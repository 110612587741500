import React from 'react'
import SbEditable from 'storyblok-react'
import {
  Box,
  Typography,
  List,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import ACC_THEME from '@themes'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: '24px',
    marginBottom: '14px',
  },
  lifeCycleHeadline: {
    textTransform: 'uppercase',
    marginBottom: '1rem',
    '&>span': {
      fontSize: '0.95rem',
    },
  },
  bottomMenu: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
  },
  bottomMenuItems: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  bottomMenuItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
}))

const TopNavProductsMenuLifecycleMenu = (props) => {
  const classes = useStyles(props)
  const noOverviewLink = props.blok.overviewLink
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))

  return (
    <SbEditable key={props.blok._uid} content={props.blok}>
      {!handHeldDevice ? (
        <Box component="span" className={classes.bottomMenu}>
          <Typography className={classes.heading}>
            {props.blok.heading}
          </Typography>
          {!noOverviewLink && (
            <Box my={2}>{renderBloks(props.blok.overviewLink)}</Box>
          )}
          <Box className={classes.bottomMenuItems}>
            {props.blok.items.map((item) => (
              <Box className={classes.bottomMenuItem} key={item._uid}>
                {renderBlok(item)}
              </Box>
            ))}
          </Box>
          <Box my={2}>{renderBloks(props.blok.platformLinks)}</Box>
        </Box>
      ) : (
        <List component="div" disablePadding>
          <ListItemText
            primary={props.blok.heading}
            className={classes.lifeCycleHeadline}
          />
          {renderBloks(props.blok.overviewLink)}
          {renderBloks(props.blok.items)}
          {renderBloks(props.blok.platformLinks)}
        </List>
      )}
    </SbEditable>
  )
}

export default TopNavProductsMenuLifecycleMenu
